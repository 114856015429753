.users-container svg {
    width: 25px;
    height: 25px;
    color: rgba(0, 0, 0, 0.87);
}

.users-container .ui.disabled.checkbox .box::after,
.users-container .ui.disabled.checkbox label,
.users-container .ui.checkbox input[disabled] ~ .box::after,
.users-container .ui.checkbox input[disabled] ~ label {
    opacity: 0.3;
}