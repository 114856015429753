.camera-control__slider {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.camera-control__slider .slick-list {
    box-shadow: inset 0px 0px 6px 1px rgba(0,0,0,0.3);
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    border-top: 1px solid #868686;
    border-bottom: 1px solid #868686;
}

.camera-control__slider .slick-list:before,
.camera-control__slider .slick-list:after {
    content: '';
    position: absolute;
    left: 4px;
    right: 0;
    margin: auto;
    height: 5px;
    width: 1px;
    background: black;
}

.camera-control__slider .slick-list:before {
    top: 0;
}

.camera-control__slider .slick-list:after {
    bottom: 0;
}

.camera-control__slider  .slick-track {
    display: flex;
    align-items: center;
}

.camera-control__slider .slick-prev {
    margin-right: 10px;
}

.camera-control__slider .slick-next {
    margin-left: 10px;
}

.camera-control__slider .slick-center .slider__value p  {
    color: #df342c;
    font-size: 24px;
}

.slider__value {
    text-align: center;
}

.slider__value p {
    display: inline-block;
    min-width: 26px;
}

@media (max-width: 767px) {
    .slider__value p {
        font-size: 12px;
    }

    .camera-control__slider .slick-center .slider__value p  {
        font-size: 18px;
    }

    .camera-control__slider .slick-arrow i.icon {
        width: 17px;
    }
}