.message-wrap {
    text-align: center;
}

.message-wrap > div {
    text-align: center;
    font-weight: bold;
}

.ui.form .user-email-input {
    margin-bottom: 1em;
}

@media (max-width: 767px) {
    .create-bulk-users .column {
        width: 100% !important;
    }
} 