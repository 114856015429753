
.camera-control .title-wrap {
    display: flex;
    justify-content: space-between;
}


.schedule__hour-marker {
    height: 100%;
    position: relative;
    width: 5%;
    /* left: 1.5em; */
    text-align: center;
}

.schedule__hour-marker:after {
    content: '';
    display: block;
    width: 1px;
    height: 90%;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    background: #aaa;
}

.schedule-controls-wrap {
    justify-content: space-between;
}

.fields.schedule-controls {
    margin: 0 !important;   
}

.schedule__segment--mobile {
    position: relative;
    padding: 0.71428571em 1.07142857em;
    cursor: pointer;
    background-color: transparent;
    transition: all 0.2s linear;
}

.schedule__segment--mobile .edit-icon {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    font-size: 2.14285714em;
    transition: all 0.2s linear;
}


.schedule__segment--mobile p {
    margin: 0 0 0.7em;
}

.schedule__segment--mobile p:last-child {
    margin: 0;
}

.schedule__segment--mobile:hover {
    background-color: rgba(0,0,0,0.2);
}

.schedule__segment--mobile:hover .edit-icon {
    opacity: 1;
}

@media (max-width: 767px) {
    .camera-control .title-wrap--schedule {
        display: block;
    }

    .camera-control .title-wrap--schedule h2 {
        margin: 0.71428571em 0;
    }

    .camera-control .title-wrap--schedule .ui.selection.dropdown {
        width: 100%;
        margin: 1.42857143em 0;
    }

    .schedule-controls {
        position: fixed;
        z-index: 999;
        background: white;
        width: 100vw;
        bottom: 0;
        left: 0;
        padding: 10px 20px;
        box-shadow: 0px 0px 6px 6px rgba(34, 36, 38, 0.15);
    }

    .schedule-controls .field {
        width: 50%;
    }

    .schedule-controls .field button {
        width: 100%;
    }

    .schedule__wrap {
        padding-bottom: 60px;
    }

    .schedule__hour-marker {
        width: 100%;
        margin: 0 0 0.5em;
        padding-left: 1em;
        text-align: left;
    }
    
    .schedule__hour-marker:after {
        width: calc(100% - 4em);
        height: 1px;
        top: 8px;
        right: 0;
        left: initial;
        margin: 0;
    }
}

@media (max-width: 767px) and (min-width: 550px) {
    .schedule__wrap {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -7.5px;
    }

    .schedule__day-wrap {
        width: calc(50% - 15px);
        margin: 0 7.5px;
    }
}